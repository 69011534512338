const referenceUserReducer = (state, action) => {
  switch (action.type) {
    case 'set_reference_users':
      return action.referenceUsers;
    case 'delete_reference_user':
      return state.filter((e) => e.id !== action.userId);
    default:
      return state;
  }
};

export default referenceUserReducer;
