/* eslint-disable babel/camelcase */
const settingBillingReducer = (state, action) => {
  switch (action.type) {
    case 'set_setting_billing':
      return action.settingBilling;
    case 'set_credit_card':
      return {
        ...state,
        billing_method: {
          ...state.billing_method,
          credit_card: action.creditCard,
        },
      };
    case 'set_open_billing_method_popup':
      return {
        ...state,
        open_billing_method: action.openBillingMethodPopup,
      };
    case 'update_payment_plan_billing_setting':
      return {
        ...state,
        payment_plan: action.paymentPlan,
      };
    default:
      return state;
  }
};

export default settingBillingReducer;
