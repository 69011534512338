import React from 'react';
import Layout from '../pages/AdminDashboard/components/Layout';
import PrivateRoute from './PrivateRoute';

/* eslint-disable react/jsx-props-no-spreading */
const AdminPrivateRoute = ({ ...rest }) => {
  return (
    <Layout>
      <PrivateRoute {...rest} />
    </Layout>
  );
};

export default AdminPrivateRoute;
