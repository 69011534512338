const tempSignupReducer = (state, action) => {
  switch (action.type) {
    case 'temp_email_password':
      return {
        ...state,
        emailTemp: action.email,
        passwordTemp: action.password,
        token: action.token,
        typeUser: action.typeUser,
        firstName: action.firstName,
        lastName: action.lastName,
        avatar: action.avatar,
        imageDefaultColor: action.imageDefaultColor,
        fundOrSyndicate: action.fundOrSyndicate,
        investerVertical: action.investerVertical,
        minInvesterAmount: action.minInvesterAmount,
        maxInvesterAmount: action.maxInvesterAmount,
        startupStates: action.startupStates,
        leadRound: action.leadRound,
        outreachInvesterMade: action.outreachInvesterMade,
        geography: action.geography,
      };
    default:
      return state;
  }
};

export default tempSignupReducer;
