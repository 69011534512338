const myAccountReducer = (state, action) => {
  switch (action.type) {
    case 'set_my_account':
      return action.myAccount;
    default:
      return state;
  }
};

export default myAccountReducer;
