import { forEach } from 'lodash';

const businessModelCanvasReducer = (state, action) => {
  switch (action.type) {
    case 'set_business_model_canvases':
      return action.businessModelCanvases;
    case 'add_business_modal_canvas':
      return state
        ? {
            ...state,
            [action.modelType]: [
              action.businessModelCanvas,
              ...state[action.modelType],
            ],
          }
        : { [action.modelType]: [action.category] };
    case 'delete_business_modal_canvas': {
      const deleteResult = {};
      forEach(state, (value, key) => {
        deleteResult[key] = value.filter(
          (item) => item.id !== action.businessModelCanvasId,
        );
      });
      return deleteResult;
    }
    case 'clear_section_business_modal_canvas':
      return { ...state, [action.modelType]: [] };
    case 'update_business_modal_canvas': {
      const updateResult = {};
      forEach(state, (value, key) => {
        updateResult[key] = value.map((item) =>
          item.id === action.businessModelCanvas.id
            ? action.businessModelCanvas
            : item,
        );
      });
      return updateResult;
    }
    default:
      return state;
  }
};

export default businessModelCanvasReducer;
