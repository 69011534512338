import Cookie from 'js-cookie';
import axios from 'axios';

const DEFAULT_API_CONFIG = {
  baseURL: '/',
  timeout: 30000,
  apiVersion: 'v1',
};
export default class Api {
  constructor(config) {
    this.client = axios.create({
      ...DEFAULT_API_CONFIG,
      ...config,
    });
  }

  headers = () => {
    return {
      /* eslint-disable babel/camelcase */
      'access-token': Cookie.get('access-token'),
      client: Cookie.get('client'),
      uid: Cookie.get('uid'),
    };
  };
}
