/**
 * @file App main entry point.
 */

import '@babel/polyfill';

import React from 'react';
import ReactDom from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

// Include the main scss file for webpack processing.
import '../css/app.scss';
import 'react-loading-skeleton/dist/skeleton.css';

import Router from './router';
import getLogger from './utils/logger';
import { StateProvider } from './store';

const log = getLogger('App');

const init = () => {
  log.info('init() :: App starts booting...');

  ReactDom.render(
    <StateProvider>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </StateProvider>,
    document.getElementById('app'),
  );
};

// init app
init();
