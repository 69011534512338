const authorReducer = (state, action) => {
  switch (action.type) {
    case 'set_authors':
      return action.authors;
    case 'add_author':
      return state ? [action.author, ...state] : [action.author];
    case 'update_author':
      return state.map((e) => (e.id === action.author.id ? action.author : e));
    case 'delete_author':
      return state.filter((e) => e.id !== action.authorId);
    default:
      return state;
  }
};

export default authorReducer;
