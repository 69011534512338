const FundingReducer = (state, action) => {
  switch (action.type) {
    case 'temp_funding':
      return {
        ...state,
        documentShared: action.documentShared,
        investorIds: action.investorIds,
        letterTitle: action.letterTitle,
        letterContent: action.letterContent,
        attachments: action.attachments,
      };
    default:
      return state;
  }
};

export default FundingReducer;
