import React, { useEffect, useContext } from 'react';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { store } from '../store';

/* eslint-disable react/jsx-props-no-spreading */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const location = useLocation();
  const globalState = useContext(store);

  useEffect(() => {
    globalState.dispatch({
      link: location.pathname,
      type: 'set_previous_link',
    });
  }, []);

  return (
    <Route {...rest}>
      {Cookie.get('access-token') ? (
        <Component />
      ) : (
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      )}
    </Route>
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.func,
};

export default PrivateRoute;
