const noteReducer = (state, action) => {
  switch (action.type) {
    case 'set_notes':
      return action.notes;
    case 'add_note':
      return state ? [action.note, ...state] : [action.note];
    case 'delete_note':
      return state.filter((e) => e.id !== action.noteId);
    case 'update_note':
      return state.map((e) => (e.id === action.note.id ? action.note : e));
    default:
      return state;
  }
};

export default noteReducer;
