import React, { createContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import combineReducers from 'react-combine-reducers';
import PaymentPlanReducer from './PaymentPlanReducer';
import TempSignupReducer from './TempSignupReducer';
import AuthorReducer from './AuthorReducer';
import CategoryReducer from './CategoryReducer';
import PostReducer from './PostReducer';
import TaskReducer from './TaskReducer';
import ReminderReducer from './ReminderReducer';
import NoteReducer from './NoteReducer';
import MyAccountReducer from './MyAccountReducer';
import SettingNotificationReducer from './SettingNotificationReducer';
import SettingBillingReducer from './SettingBillingReducer';
import ContactReducer from './ContactReducer';
import ReferenceUserReducer from './ReferenceUserReducer';
import startupReducer from './StartupReducer';
import elevatorPitchReducer from './elevatorPitchReducer';
import positioningStatementReducer from './positioningStatementReducer';
import BusinessModelCanvasReducer from './BusinessModelCanvasReducer';
import DocumentReducer from './DocumentReducer';
import RevenueEstimateReducer from './RevenueEstimateReducer';
import StartupNoteReducer from './StartupNoteReducer';
import BusinessPlanBuilderReducer from './BusinessPlanBuilderReducer';
import PlanReducer from './PlanReducer';
import ActivityReducer from './ActivityReducer';
import StartupMarketingPlanReducer from './StartupMarketingPlanReducer';
import FinancialModelReducer from './FinancialModelReducer';
import SprintReducer from './SprintReducer';
import InvestorReducer from './InvestorReducer';
import FundingReducer from './FundingReducer';
import PreviousLinkReducer from './PreviousLinkReducer';
import PitchDeckReducer from './PitchDeckReducer';
import CurrentPitchDeckReducer from './CurrentPitchDeckReducer';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [rootReducer, initialProfile] = combineReducers({
    paymentPlan: [PaymentPlanReducer, undefined],
    tempSignup: [TempSignupReducer, undefined],
    authors: [AuthorReducer, undefined],
    categories: [CategoryReducer, undefined],
    posts: [PostReducer, undefined],
    tasks: [TaskReducer, undefined],
    notes: [NoteReducer, undefined],
    myAccount: [MyAccountReducer, undefined],
    reminders: [ReminderReducer, { date: undefined, values: undefined }],
    settingNotifications: [SettingNotificationReducer, undefined],
    settingBilling: [SettingBillingReducer, undefined],
    contacts: [ContactReducer, { values: undefined, filted: undefined }],
    referenceUsers: [ReferenceUserReducer, undefined],
    startups: [startupReducer, undefined],
    elevatorPitch: [elevatorPitchReducer, undefined],
    positioningStatement: [positioningStatementReducer, undefined],
    businessModelCanvases: [BusinessModelCanvasReducer, undefined],
    documents: [DocumentReducer, undefined],
    revenueEstimate: [RevenueEstimateReducer, undefined],
    startupNotes: [StartupNoteReducer, undefined],
    businessPlanBuilder: [BusinessPlanBuilderReducer, undefined],
    plans: [PlanReducer, undefined],
    activities: [ActivityReducer, undefined],
    startupMarketingPlan: [StartupMarketingPlanReducer, undefined],
    financialModels: [FinancialModelReducer, undefined],
    sprints: [SprintReducer, undefined],
    investors: [InvestorReducer, undefined],
    funding: [FundingReducer, undefined],
    previousLink: [PreviousLinkReducer, undefined],
    pitchDecks: [PitchDeckReducer, undefined],
    currentPitchDeck: [CurrentPitchDeckReducer, undefined],
  });

  const [state, dispatch] = useReducer(rootReducer, initialProfile);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

StateProvider.propTypes = {
  children: PropTypes.node,
};

export { store, StateProvider };
