const categoryReducer = (state, action) => {
  switch (action.type) {
    case 'set_categories':
      return action.categories;
    case 'add_category':
      return state ? [action.category, ...state] : [action.category];
    case 'delete_category':
      return state.filter((e) => e.id !== action.categoryId);
    case 'update_category':
      return state.map((e) =>
        e.id === action.category.id ? action.category : e,
      );
    default:
      return state;
  }
};

export default categoryReducer;
