const PitchDeckReducer = (state, action) => {
  switch (action.type) {
    case 'set_pitch_decks':
      return action.pitchDecks;
    case 'add_pitch_deck':
      return state ? [action.pitchDeck, ...state] : [action.pitchDeck];
    default:
      return state;
  }
};

export default PitchDeckReducer;
