const settingNotificationReducer = (state, action) => {
  switch (action.type) {
    case 'set_setting_notifications':
      return action.settingNotifications;
    default:
      return state;
  }
};

export default settingNotificationReducer;
