import moment from 'moment';

const reminderReducer = (state, action) => {
  let result;
  switch (action.type) {
    case 'set_reminders':
      return { ...state, values: action.reminders };
    case 'add_reminder':
      if (state.date === undefined) {
        if (
          moment().format('YYYY-MM-DD') === action.reminder.date.substr(0, 10)
        ) {
          result = state.values
            ? { ...state, values: [action.reminder, ...state.values] }
            : { ...state, values: [action.reminder] };
        }
      } else if (
        state.date.format('YYYY-MM-DD') === action.reminder.date.substr(0, 10)
      ) {
        result = state.values
          ? { ...state, values: [action.reminder, ...state.values] }
          : { ...state, values: [action.reminder] };
      }
      return result ?? state;
    case 'delete_reminder':
      return {
        ...state,
        values: state.values.filter((e) => e.id !== action.reminderId),
      };
    case 'update_reminder':
      return (state.date || moment()).format('YYYY-MM-DD') ===
        action.reminder.date.substr(0, 10)
        ? {
            ...state,
            values: state.values.map((e) =>
              e.id === action.reminder.id ? action.reminder : e,
            ),
          }
        : {
            ...state,
            values: state.values.filter((e) => e.id !== action.reminder.id),
          };
    case 'set_current_reminder_date':
      return { ...state, date: action.reminderDate };
    default:
      return state;
  }
};

export default reminderReducer;
