const paymentPlanReducer = (state, action) => {
  switch (action.type) {
    case 'set_payment_plan':
      return action.paymentPlan;
    default:
      return state;
  }
};

export default paymentPlanReducer;
