const planReducer = (state, action) => {
  switch (action.type) {
    case 'set_plans':
      return action.plans;
    default:
      return state;
  }
};

export default planReducer;
