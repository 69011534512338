import { sortBy } from 'lodash';

const SprintReducer = (state, action) => {
  switch (action.type) {
    case 'set_sprints':
      return action.sprints;
    case 'add_sprint':
      return state ? [...state, action.sprint] : [action.sprint];
    case 'update_sprint':
      return state.map((e) => (e.id === action.sprint.id ? action.sprint : e));
    case 'update_sprint_with_feature':
      return state.map((e) =>
        `${e.id}` === `${action.sprintId}`
          ? { ...e, features: [...e.features, action.feature] }
          : e,
      );
    case 'update_feature':
      return state
        .map((e) =>
          `${e.id}` === `${action.sprintId}`
            ? {
                ...e,
                features: e.features.filter((f) => f.id !== action.feature.id),
              }
            : e,
        )
        .map((e) =>
          `${e.id}` === `${action.sprintId}`
            ? {
                ...e,
                features: sortBy([...e.features, action.feature], ['id']),
              }
            : e,
        );
    case 'update_sprint_with_edit_feature':
      return state.map((e) =>
        `${e.id}` === `${action.sprintId}`
          ? {
              ...e,
              features: e.features.map((feature) =>
                feature.id === action.featureId ? action.feature : feature,
              ),
            }
          : e,
      );
    case 'update_show_sprint':
      return state.map((e) =>
        `${e.id}` === `${action.sprintId}` ? { ...e, show: !e.show } : e,
      );
    case 'delete_sprint':
      return state.filter((e) => e.id !== action.sprintId);
    case 'delete_feature':
      return state.map((e) =>
        e.id === action.sprintId
          ? {
              ...e,
              features: e.features.filter((fea) => fea.id !== action.featureId),
            }
          : e,
      );
    default:
      return state;
  }
};

export default SprintReducer;
