const BusinessPlanBuilderReducer = (state, action) => {
  switch (action.type) {
    case 'set_business_plan_builder':
      return action.businessPlanBuilder;
    default:
      return state;
  }
};

export default BusinessPlanBuilderReducer;
