import { forEach } from 'lodash';

const contactReducer = (state, action) => {
  switch (action.type) {
    case 'set_contacts':
      return { ...state, values: action.contacts };
    case 'set_contacts_filted':
      return { ...state, filted: action.contacts };
    case 'add_contacts':
      return state.values
        ? {
            ...state,
            values: [...action.contacts, ...state.values],
          }
        : {
            ...state,
            values: [...action.contacts],
          };
    case 'add_contact': {
      const currentTags = state.filted ? state.filted.map((x) => x.tag) : [];
      const valueContactsFilted = [];

      forEach(state.filted, ({ tag, values }) => {
        valueContactsFilted.push({
          tag,
          values: action.contact.tags.includes(tag)
            ? [action.contact, ...values]
            : values,
        });
      });
      forEach(
        action.contact.tags.filter((x) => !currentTags.includes(x)),
        (tag) => {
          valueContactsFilted.unshift({
            tag,
            values: [action.contact],
          });
        },
      );
      return state.values
        ? {
            ...state,
            values: [action.contact, ...state.values],
            filted: valueContactsFilted.filter(
              ({ values }) => values.length > 0,
            ),
          }
        : {
            ...state,
            values: [action.contact],
            filted: valueContactsFilted.filter(
              ({ values }) => values.length > 0,
            ),
          };
    }
    case 'update_contact': {
      const valueContactsFilted = [];
      const contactsFilted = [];
      forEach(state.filted, ({ tag, values }) => {
        contactsFilted.push({
          tag,
          values: values.filter((e) => e.id !== action.contactId),
        });
      });

      const currentTags = contactsFilted.map((x) => x.tag);
      forEach(contactsFilted, ({ tag, values }) => {
        valueContactsFilted.push({
          tag,
          values: action.contact.tags.includes(tag)
            ? [
                action.contact,
                ...values.filter((item) => item.id !== action.contact.id),
              ]
            : values,
        });
      });

      forEach(
        action.contact.tags.filter((x) => !currentTags.includes(x)),
        (tag) => {
          valueContactsFilted.unshift({
            tag,
            values: [action.contact],
          });
        },
      );

      return {
        ...state,
        values: state.values.map((e) =>
          e.id === action.contact.id ? action.contact : e,
        ),
        filted: valueContactsFilted.filter(({ values }) => values.length > 0),
      };
    }

    case 'delete_contact': {
      const valueContactsFilted = [];
      forEach(state.filted, ({ tag, values }) => {
        valueContactsFilted.push({
          tag,
          values: values.filter((e) => e.id !== action.contactId),
        });
      });

      return {
        ...state,
        filted: valueContactsFilted.filter(({ values }) => values.length > 0),
        contacts: state.values.filter((e) => e.id !== action.contactId),
      };
    }
    default:
      return state;
  }
};

export default contactReducer;
