import { each } from 'lodash';
import V1 from './api';

class UserSetting extends V1 {
  getMyAccount = () => {
    return this.client.get('/api/v1/my_account', {
      headers: this.headers(),
    });
  };

  updateMyAccount = (values) => {
    const formData = new FormData();
    each(values, (value, key) => {
      formData.append(key, value);
    });

    return this.client.put('/api/v1/my_account', formData, {
      headers: this.headers(),
    });
  };

  getBillingMethod = () => {
    return this.client.get('/api/v1/billing_method', {
      headers: this.headers(),
    });
  };

  updateCreditCard = (values) => {
    return this.client.put('/api/v1/credit_cards', values, {
      headers: this.headers(),
    });
  };

  getUserBilling = () => {
    return this.client.get('/api/v1/user_billings', {
      headers: this.headers(),
    });
  };

  doneGuide = (type) => {
    return this.client.put(
      '/api/v1/done_guide',
      { type },
      {
        headers: this.headers(),
      },
    );
  };
}

const UserSettingApi = new UserSetting();

export default UserSettingApi;
