import React from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Image, Nav } from 'react-bootstrap';
import logo from '../../../../image/logo_admin.svg';
import overview from '../../../../image/overview.svg';
import overviewActive from '../../../../image/overview_active.svg';
import posts from '../../../../image/posts.svg';
import postsActive from '../../../../image/posts_active.svg';
import categories from '../../../../image/categories.svg';
import categoriesActive from '../../../../image/categories_active.svg';
import authors from '../../../../image/authors.svg';
import authorsActive from '../../../../image/authors_active.svg';
import plans from '../../../../image/plans.svg';
import plansActive from '../../../../image/plans_active.svg';
import StartupIcon from '../../../../image/startup_default_icon.svg';
import StartupActiveIcon from '../../../../image/startup_default_active_icon.svg';
import settings from '../../../../image/settings.svg';
import settingsActive from '../../../../image/settings_active.svg';
import userGroup from '../../../../image/user_group.svg';
import userGroupActive from '../../../../image/user_group_active.svg';
import eventHistory from '../../../../image/event_history.svg';
import eventHistoryActive from '../../../../image/event_history_active.svg';
import logout from '../../../../image/logout.svg';

const Sidebar = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <Nav className="admin-dashboard-sidebar flex-column">
      <Nav.Link to="/dashboard" as={Link}>
        <div className="d-flex justify-content-center">
          <Image src={logo} />
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/overview" as={Link}>
        <div className="d-flex align-items-center">
          <Image
            src={pathname.includes('overview') ? overviewActive : overview}
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('overview') ? 1 : 0.4 }}
          >
            OVERVIEW
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/users" as={Link}>
        <div className="d-flex align-items-center">
          <Image
            src={pathname.includes('users') ? userGroupActive : userGroup}
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('users') ? 1 : 0.4 }}
          >
            USERS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/event-history" as={Link}>
        <div className="d-flex align-items-center">
          <Image
            src={
              pathname.includes('event-history')
                ? eventHistoryActive
                : eventHistory
            }
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('event-history') ? 1 : 0.4 }}
          >
            EVENT HISTORY
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/posts" as={Link}>
        <div className="d-flex align-items-center">
          <Image src={pathname.includes('posts') ? postsActive : posts} />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('posts') ? 1 : 0.4 }}
          >
            POSTS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/categories" as={Link}>
        <div className="d-flex align-items-center">
          <Image
            src={
              pathname.includes('categories') ? categoriesActive : categories
            }
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('categories') ? 1 : 0.4 }}
          >
            CATEGORIES
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/authors" as={Link}>
        <div className="d-flex align-items-center">
          <Image src={pathname.includes('authors') ? authorsActive : authors} />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('authors') ? 1 : 0.4 }}
          >
            AUTHORS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/plans" as={Link}>
        <div className="d-flex align-items-center">
          <Image src={pathname.includes('plans') ? plansActive : plans} />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('plans') ? 1 : 0.4 }}
          >
            PLANS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link to="/admin-dashboard/startups" className="mb-auto" as={Link}>
        <div className="d-flex align-items-center">
          <Image
            src={
              pathname.includes('startups') ? StartupActiveIcon : StartupIcon
            }
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('startups') ? 1 : 0.4 }}
          >
            STARTUPS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link href="/admin-dashboard/settings">
        <div className="d-flex align-items-center">
          <Image
            src={pathname.includes('settings') ? settingsActive : settings}
          />
          <p
            className="text-white ml-3"
            style={{ opacity: pathname.includes('settings') ? 1 : 0.4 }}
          >
            SETTINGS
          </p>
        </div>
      </Nav.Link>
      <Nav.Link>
        <div className="d-flex align-items-center">
          <Image src={logout} />
          <p className="text-white ml-3" style={{ opacity: 0.4 }}>
            LOG OUT
          </p>
        </div>
      </Nav.Link>
    </Nav>
  );
};

export default Sidebar;
