import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Cookie from 'js-cookie';
import { Button, Form, Image, Navbar, NavDropdown } from 'react-bootstrap';
import bellDot from '../../../../image/bell_dot.svg';
import userAvatar from '../../../../image/user_avatar.svg';
import settingIcon from '../../../../image/setting_icon.svg';
import logoutIcon from '../../../../image/logout_icon.svg';
import { store } from '../../../store';
import UserSettingApi from '../../../services/api/UserSettingApi';

const Header = () => {
  const [query, setQuery] = useState('');
  const globalState = useContext(store);
  const [myAccount, setMyAccount] = useState({});
  const history = useHistory();

  useEffect(() => {
    UserSettingApi.getMyAccount().then((res) => {
      if (res.data.data.role === 'is_normal_user') {
        history.goBack();
      }
      globalState.dispatch({
        myAccount: res.data.data,
        type: 'set_my_account',
      });
    });
  }, []);

  useEffect(() => {
    if (globalState.state?.myAccount) {
      setMyAccount(globalState.state.myAccount);
    }
  }, [globalState]);

  const handleLogout = () => {
    Cookie.set('access-token', '');
    Cookie.set('client', '');
    Cookie.set('uid', '');
    Cookie.set('confirmed', '');
    Cookie.set('calendar_authorization', '');
    history.push('/login');
  };
  return (
    <Navbar className="admin-dashboard-header">
      <div className="search-wrapper">
        <Form.Control
          type="email"
          placeholder="Search posts, categories, authors..."
          className="search-input"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <Link to={`/admin-dashboard/search/${query}`}>
          <Button className="find-btn" onClick={() => setQuery('')}>
            Find
          </Button>
        </Link>
      </div>
      <div className="d-flex align-items-center">
        <Image src={bellDot} className="mr-5" />
        <NavDropdown
          title={
            <span className="admin-text mr-5">
              <Image
                src={myAccount.avatar_url || userAvatar}
                style={{ objectFit: 'cover' }}
                className="mr-3"
                width={40}
                height={40}
                roundedCircle
              />
              Admin
            </span>
          }
          id="collasible-nav-dropdown"
          className="founders-library-item account-dropdown"
        >
          <NavDropdown.Item href="/settings/my-account">
            <Image src={settingIcon} width={16} height={16} className="mr-2" />
            My Settings
          </NavDropdown.Item>
          <NavDropdown.Item href="#" onClick={() => handleLogout()}>
            <Image src={logoutIcon} width={16} height={16} className="mr-2" />
            Log Out
          </NavDropdown.Item>
        </NavDropdown>
      </div>
    </Navbar>
  );
};

export default Header;
