const taskReducer = (state, action) => {
  switch (action.type) {
    case 'set_tasks':
      return action.tasks;
    case 'add_task':
      return state ? [action.task, ...state] : [action.task];
    case 'delete_task':
      return state.filter((e) => e.id !== action.taskId);
    case 'update_task':
      return state.map((e) => (e.id === action.task.id ? action.task : e));
    default:
      return state;
  }
};

export default taskReducer;
