const startupNoteReducer = (state, action) => {
  switch (action.type) {
    case 'set_startup_notes':
      return action.notes;
    case 'add_startup_note':
      return state ? [action.note, ...state] : [action.note];
    case 'delete_startup_note':
      return state.filter((e) => e.id !== action.noteId);
    case 'update_startup_note':
      return state.map((e) => (e.id === action.note.id ? action.note : e));
    default:
      return state;
  }
};

export default startupNoteReducer;
