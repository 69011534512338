/**
 * @file App component.
 */

import React, { Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import PrivateRoute from './components/PrivateRoute';
import AdminPrivateRoute from './components/AdminPrivateRoute';

const StartupInvestment = React.lazy(() =>
  import('./pages/Dashboard/components/Investment/DashboardInvestment'),
);
const Dashboard = React.lazy(() => import('./pages/Dashboard/Dashboard'));
const FoundersLibrary = React.lazy(() =>
  import('./pages/FoundersLibrary/FoundersLibrary'),
);
const Blogs = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Blogs/Blogs'),
);
const BlogDetail = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Blogs/BlogDetail'),
);
// const Login = React.lazy(() => import('./pages/Onboarding/Login'));
const Login = React.lazy(() => {
  return Promise.all([
    import('./pages/Onboarding/Login'),
    new Promise((resolve) => setTimeout(resolve, 300)),
  ]).then(([moduleExports]) => moduleExports);
});
const Unlock = React.lazy(() => import('./pages/Onboarding/Unlock'));
const ResetPassword = React.lazy(() =>
  import('./pages/Onboarding/ResetPassword'),
);
const ForgotPassword = React.lazy(() =>
  import('./pages/Onboarding/ForgotPassword'),
);
const Signup = React.lazy(() => import('./pages/Onboarding/Signup'));
const CreateAccount = React.lazy(() =>
  import('./pages/Onboarding/CreateAccount'),
);
const Thankyou = React.lazy(() => import('./pages/Onboarding/Thankyou'));
const EmailVerify = React.lazy(() => import('./pages/Onboarding/EmailVerify'));
const Downloadables = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Downloadables/Downloadables'),
);
const DownloadableProduct = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Downloadables/DownloadableProduct'),
);
const Podcasts = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Podcasts/Podcasts'),
);
const PodcastDetail = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Podcasts/PodcastDetail'),
);
const Videos = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Videos/Videos'),
);
const VideoDetail = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Videos/VideoDetail'),
);
const Favorites = React.lazy(() =>
  import('./pages/FoundersLibrary/parts/Favorites/Favorites'),
);
const NewPost = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Post/NewPost'),
);
const Categories = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Category/Categories'),
);
const Posts = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Post/Posts'),
);
const StartupSample = React.lazy(() =>
  import('./pages/AdminDashboard/parts/StartupSample'),
);
const StartupSampleView = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/StartupSampleView'
  ),
);
const IntroPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/IntroPage'
  ),
);
const ExecutiveSummaryPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/ExecutiveSummaryPage'
  ),
);
const CompanyOverviewPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/CompanyOverviewPage'
  ),
);
const ProductServicePage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/ProductServicePage'
  ),
);
const MarketAnalysisPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/MarketAnalysisPage'
  ),
);
const MarketingPlanPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/MarketingPlanPage'
  ),
);
const SaleFinancialPage = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/bizplanBuilder/SaleFinancialPage'
  ),
);
const AdminFinancialModel = React.lazy(() =>
  import('./pages/AdminDashboard/parts/StartupSample/parts/finacialModel'),
);
const AdminMarketingPlanIntro = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/marketingPlan/Intro'
  ),
);
const AdminMarketingPlanTargerMarket = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/marketingPlan/TargetMarket'
  ),
);
const AdminMarketingPlanSmartGoal = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/marketingPlan/SmartGoal'
  ),
);
const AdminMarketingPlanChannels = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/marketingPlan/Channels'
  ),
);
const AdminMarketingPlanStrategy = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/marketingPlan/Strategy'
  ),
);
const TimeLine = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/productRoadMapper/TimeLine'
  ),
);
const Overview = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Overview/Overview'),
);
const Plans = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Plan/Plans'),
);
const NewCategory = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Category/NewCategory'),
);
const NewAuthor = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Author/NewAuthor'),
);
const Authors = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Author/Authors'),
);
const SearchResult = React.lazy(() =>
  import('./pages/AdminDashboard/parts/SearchResult/SearchResult'),
);
const PersonalCRM = React.lazy(() => import('./pages/PersonalCRM/PersonalCRM'));
const ContactDetail = React.lazy(() =>
  import('./pages/PersonalCRM/parts/Contact/ContactDetail'),
);
const ContactAll = React.lazy(() =>
  import('./pages/PersonalCRM/parts/Contact/ContactAll'),
);
const MyAccount = React.lazy(() =>
  import('./pages/Settings/parts/MyAccount/MyAccount'),
);
const Billing = React.lazy(() =>
  import('./pages/Settings/parts/Billing/Billing'),
);
const Notifications = React.lazy(() =>
  import('./pages/Settings/parts/Notifications/Notifications'),
);
const Users = React.lazy(() => import('./pages/Settings/parts/Users/Users'));
const StartupStarter = React.lazy(() =>
  import('./pages/StartupStarter/StartupStarter'),
);
const StartupStarterOverView = React.lazy(() =>
  import('./pages/StartupStarter/StartupStarterOverView'),
);
const ElevatorPitch = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessModel/ElevatorPitch'),
);
const ElevatorPitchUpdate = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessModel/ElevatorPitchUpdate'),
);
const PositioningStatement = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessModel/PositioningStatement'),
);
const PositioningStatementUpdate = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessModel/PositioningStatementUpdate'
  ),
);
const BusinessModelCanvas = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessModelCanvas'),
);
const Document = React.lazy(() =>
  import('./pages/StartupStarter/parts/Document'),
);
const RevenueEstimate = React.lazy(() =>
  import('./pages/StartupStarter/parts/RevenueEstimate'),
);
const StartupNotes = React.lazy(() =>
  import('./pages/StartupStarter/parts/Notes'),
);
const BusinessPlanBuilderCompanySettings = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/CompanySettings'
  ),
);
const BusinessPlanBuilderExecutiveSummaryPage = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/ExecutiveSummaryPage'
  ),
);
const BusinessPlanBuilderIntroPage = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessPlanBuilder/parts/IntroPage'),
);
const BusinessPlanBuilderMarketingPlan = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/MarketingPlan'
  ),
);
const BusinessPlanBuilderCompanyOverview = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/CompanyOverview'
  ),
);
const BusinessPlanBuilderProductAndServicePage = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/ProductAndServicePage'
  ),
);
const BusinessPlanBuilderSaleAndFinancialPage = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/SaleAndFinancialPage'
  ),
);
const BusinessPlanBuilderMarketAnalysisPage = React.lazy(() =>
  import(
    './pages/StartupStarter/parts/BusinessPlanBuilder/parts/MarketAnalysisPage'
  ),
);
const BusinessPlanBuilderPdf = React.lazy(() =>
  import('./pages/StartupStarter/parts/BusinessPlanBuilder/parts/ExportPdf'),
);
const FinancialModels = React.lazy(() =>
  import('./pages/StartupStarter/parts/FinancialModel'),
);
const MarketingPlanIntro = React.lazy(() =>
  import('./pages/StartupStarter/parts/MarketingPlan/parts/IntroPage'),
);
const MarketingPlanTargetMarket = React.lazy(() =>
  import('./pages/StartupStarter/parts/MarketingPlan/parts/TargetMarket'),
);
const SmartGoal = React.lazy(() =>
  import('./pages/StartupStarter/parts/MarketingPlan/parts/SmartGoal'),
);
const MarketingChannel = React.lazy(() =>
  import('./pages/StartupStarter/parts/MarketingPlan/parts/MarketingChannel'),
);
const MarketingStrategy = React.lazy(() =>
  import('./pages/StartupStarter/parts/MarketingPlan/parts/MarketingStrategy'),
);
const ProductRoadMapper = React.lazy(() =>
  import('./pages/StartupStarter/parts/ProductRoadMapper'),
);
const PitchDeck = React.lazy(() =>
  import('./pages/StartupStarter/parts/PitchDeckBuilder'),
);
const PitchDeckDetail = React.lazy(() =>
  import('./pages/StartupStarter/parts/PitchDeckBuilder/PitchDeckDetail'),
);
const VerticalInvestor = React.lazy(() =>
  import('./pages/Onboarding/VerticalInvestor'),
);
const AmountInvestor = React.lazy(() =>
  import('./pages/Onboarding/AmountInvestor'),
);
const RoundInvestor = React.lazy(() =>
  import('./pages/Onboarding/RoundInvestor'),
);
const NoteInvestor = React.lazy(() =>
  import('./pages/Onboarding/NoteInvestor'),
);
const SprintDetail = React.lazy(() =>
  import('./pages/StartupStarter/parts/ProductRoadMapper/KanbanPage'),
);
const ImpactEffort = React.lazy(() =>
  import('./pages/StartupStarter/parts/ProductRoadMapper/ImpactEffort'),
);
const TimeLineDetail = React.lazy(() =>
  import('./pages/StartupStarter/parts/ProductRoadMapper/TimelinePage'),
);
const Kanban = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/productRoadMapper/KanBan'
  ),
);
const AdminImpactEffort = React.lazy(() =>
  import(
    './pages/AdminDashboard/parts/StartupSample/parts/productRoadMapper/ImpactEffort'
  ),
);
const AdminUsers = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Users/UserList'),
);
const AdminUserDetail = React.lazy(() =>
  import('./pages/AdminDashboard/parts/Users/UserDetail'),
);
const AdminEventHistory = React.lazy(() =>
  import('./pages/AdminDashboard/parts/EventHistory/EventHistory'),
);

const Router = () => {
  return (
    <Suspense
      fallback={
        <div className="d-flex justify-content-center mt-5">
          <Spinner animation="border" style={{ color: '#46608A' }} />
        </div>
      }
    >
      <Switch>
        <Route exact path="/" render={() => <Redirect to="/dashboard" />} />
        <Route path="/signup" component={Signup} />
        <Route path="/login" component={Login} />
        <Route path="/unlock" component={Unlock} />
        <Route path="/reset-password" component={ResetPassword} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/create-account" component={CreateAccount} />
        <Route path="/investor-account/vertical" component={VerticalInvestor} />
        <Route path="/investor-account/amount" component={AmountInvestor} />
        <Route path="/investor-account/round" component={RoundInvestor} />
        <Route path="/investor-account/complete" component={NoteInvestor} />
        <Route path="/thankyou" component={Thankyou} />
        <Route path="/email-verify" component={EmailVerify} />
        <PrivateRoute exact path="/dashboard" component={Dashboard} />
        <PrivateRoute
          exact
          path="/dashboard/company/:startupId"
          component={StartupInvestment}
        />
        <PrivateRoute
          exact
          path="/founders-library"
          component={FoundersLibrary}
        />
        <PrivateRoute exact path="/founders-library/blogs" component={Blogs} />
        <PrivateRoute
          exact
          path="/founders-library/blogs/:blogId"
          component={BlogDetail}
        />
        <PrivateRoute
          exact
          path="/founders-library/downloads"
          component={Downloadables}
        />
        <PrivateRoute
          exact
          path="/founders-library/downloads/:downloadId"
          component={DownloadableProduct}
        />
        <PrivateRoute
          exact
          path="/founders-library/podcasts"
          component={Podcasts}
        />
        <PrivateRoute
          exact
          path="/founders-library/podcasts/:podcastId"
          component={PodcastDetail}
        />
        <PrivateRoute
          exact
          path="/founders-library/videos"
          component={Videos}
        />
        <PrivateRoute
          exact
          path="/founders-library/videos/:videoId"
          component={VideoDetail}
        />
        <PrivateRoute
          exact
          path="/founders-library/favorites"
          component={Favorites}
        />
        <PrivateRoute exact path="/personal-crm" component={PersonalCRM} />
        <PrivateRoute
          exact
          path="/personal-crm/contacts/:contactId"
          component={ContactDetail}
        />
        <PrivateRoute
          exact
          path="/personal-crm/contacts"
          component={ContactAll}
        />
        <Route
          exact
          path="/settings"
          render={() => <Redirect to="/settings/my-account" />}
        />
        <PrivateRoute exact path="/settings/my-account" component={MyAccount} />
        <PrivateRoute exact path="/settings/billing" component={Billing} />
        <PrivateRoute
          exact
          path="/settings/notifications"
          component={Notifications}
        />
        <PrivateRoute exact path="/settings/users" component={Users} />
        <PrivateRoute
          exact
          path="/startup-starter"
          component={StartupStarter}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/overview"
          component={StartupStarterOverView}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/business-model-canvas"
          component={BusinessModelCanvas}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/revenue-estimate"
          component={RevenueEstimate}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/elevator-pitch"
          component={ElevatorPitch}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/elevator-pitch/edit"
          component={ElevatorPitchUpdate}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/positioning-statement"
          component={PositioningStatement}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-model/positioning-statement/edit"
          component={PositioningStatementUpdate}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/documents"
          component={Document}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/notes"
          component={StartupNotes}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/company-setting"
          component={BusinessPlanBuilderCompanySettings}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/marketing-plan"
          component={BusinessPlanBuilderMarketingPlan}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/executive-summary"
          component={BusinessPlanBuilderExecutiveSummaryPage}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/intro"
          component={BusinessPlanBuilderIntroPage}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/company-overview"
          component={BusinessPlanBuilderCompanyOverview}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/product-and-services"
          component={BusinessPlanBuilderProductAndServicePage}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/sale-and-financial"
          component={BusinessPlanBuilderSaleAndFinancialPage}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/market-analysis"
          component={BusinessPlanBuilderMarketAnalysisPage}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/business-plan-builder/export-pdf"
          component={BusinessPlanBuilderPdf}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/marketing-plan/intro"
          component={MarketingPlanIntro}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/marketing-plan/target-market"
          component={MarketingPlanTargetMarket}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/marketing-plan/smart-goal"
          component={SmartGoal}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/marketing-plan/channels"
          component={MarketingChannel}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/marketing-plan/strategy"
          component={MarketingStrategy}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/financial-models"
          component={FinancialModels}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/product-roadmapper"
          component={ProductRoadMapper}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/pitch-deck"
          component={PitchDeck}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/pitch-deck/:pitchDeckId"
          component={PitchDeckDetail}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/kanban"
          component={SprintDetail}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/impact-effort"
          component={ImpactEffort}
        />
        <PrivateRoute
          exact
          path="/startup-starter/:startupId/timeline"
          component={TimeLineDetail}
        />
        <Route
          exact
          path="/admin-dashboard"
          render={() => <Redirect to="/admin-dashboard/overview" />}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/overview"
          component={Overview}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/users"
          component={AdminUsers}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/users/:userId"
          component={AdminUserDetail}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/event-history"
          component={AdminEventHistory}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/posts"
          component={Posts}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/posts/new"
          component={NewPost}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/posts/:postId/edit"
          component={NewPost}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/categories"
          component={Categories}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/categories/new"
          component={NewCategory}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/categories/:categoryId/edit"
          component={NewCategory}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/plans"
          component={Plans}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/authors"
          component={Authors}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups"
          component={StartupSample}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/company-setting"
          component={StartupSampleView}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/intro"
          component={IntroPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/executive-summary"
          component={ExecutiveSummaryPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/company-overview"
          component={CompanyOverviewPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/product-and-services"
          component={ProductServicePage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/market-analysis"
          component={MarketAnalysisPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/marketing-plan"
          component={MarketingPlanPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/bizplan_builder/sale-and-financial"
          component={SaleFinancialPage}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/financial-model"
          component={AdminFinancialModel}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/marketing_plan/intro"
          component={AdminMarketingPlanIntro}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/marketing_plan/target-market"
          component={AdminMarketingPlanTargerMarket}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/marketing_plan/smart-goal"
          component={AdminMarketingPlanSmartGoal}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/marketing_plan/channels"
          component={AdminMarketingPlanChannels}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/marketing_plan/strategy"
          component={AdminMarketingPlanStrategy}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/product-roadmapper/timeline"
          component={TimeLine}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/product-roadmapper/kanban"
          component={Kanban}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/startups/:startupId/product-roadmapper/impact-effort"
          component={AdminImpactEffort}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/authors/new"
          component={NewAuthor}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/authors/:authorId/edit"
          component={NewAuthor}
        />
        <AdminPrivateRoute
          exact
          path="/admin-dashboard/search/:query"
          component={SearchResult}
        />
      </Switch>
    </Suspense>
  );
};

export default Router;
