const previousLinkReducer = (state, action) => {
  switch (action.type) {
    case 'set_previous_link':
      return action.link;
    default:
      return state;
  }
};

export default previousLinkReducer;
