const financialModelReducer = (state, action) => {
  switch (action.type) {
    case 'set_financial_model':
      return action.financialModels;
    default:
      return state;
  }
};

export default financialModelReducer;
