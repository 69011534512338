const revenueEstimateReducer = (state, action) => {
  switch (action.type) {
    case 'set_revenue_estimate':
      return action.revenueEstimate;
    default:
      return state;
  }
};

export default revenueEstimateReducer;
