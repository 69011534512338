import React from 'react';
import { ToastContainer } from 'react-toastify';
import PropTypes from 'prop-types';
import Sidebar from './Sidebar';
import Header from './Header';

const Layout = ({ children }) => {
  return (
    <div className="admin-layout-wrapper">
      <ToastContainer autoClose={2500} />
      <div className="sidebar-wrapper d-none d-md-block">
        <Sidebar />
      </div>
      <div className="content-wrapper">
        <Header />
        {children}
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.element,
};

export default Layout;
