const elevatorPitchReducer = (state, action) => {
  switch (action.type) {
    case 'set_elevator_pitch':
      return action.elevatorPitch;
    default:
      return state;
  }
};

export default elevatorPitchReducer;
