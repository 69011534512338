const positioningStatementReducer = (state, action) => {
  switch (action.type) {
    case 'set_positioning_statement':
      return action.positioningStatement;
    default:
      return state;
  }
};

export default positioningStatementReducer;
