const startupReducer = (state, action) => {
  switch (action.type) {
    case 'set_startups':
      return action.startups;
    case 'add_startup':
      return state ? [action.startup, ...state] : [action.startup];
    case 'update_startup':
      return state.map((e) =>
        e.id === action.startup.id ? action.startup : e,
      );
    case 'delete_startup':
      return state.filter((e) => e.id !== action.startupId);
    default:
      return state;
  }
};

export default startupReducer;
