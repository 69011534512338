const CurrentPitchDeckReducer = (state, action) => {
  switch (action.type) {
    case 'set_pitch_deck':
      return action.pitchDeck;
    default:
      return state;
  }
};

export default CurrentPitchDeckReducer;
