const postReducer = (state, action) => {
  switch (action.type) {
    case 'set_posts':
      return action.posts;
    case 'add_post':
      return state ? [action.post, ...state] : [action.post];
    case 'delete_post':
      return state.filter((e) => e.id !== action.postId);
    case 'update_post':
      return state.map((e) => (e.id === action.post.id ? action.post : e));
    default:
      return state;
  }
};

export default postReducer;
