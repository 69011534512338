const DocumentReducer = (state, action) => {
  switch (action.type) {
    case 'set_startup_documents':
      return action.documents;
    default:
      return state;
  }
};

export default DocumentReducer;
