const StartupMarketingPlanReducer = (state, action) => {
  switch (action.type) {
    case 'set_startup_marketing_plan':
      return action.startupMarketingPlan;
    case 'delete_channel':
      return {
        ...state,
        channels: state.channels.filter((item) => item.id !== action.channelId),
      };
    default:
      return state;
  }
};

export default StartupMarketingPlanReducer;
